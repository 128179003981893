import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class ThanksJaPage extends React.Component {
    componentDidMount() {
        if (document.referrer === '') {
            window.location.href = 'https://www.cookinburger.com/ja/';
        } else {
            setTimeout(function(){
              window.location.href = document.referrer;//'https://game.cookinburger.com/';
            }, 5*1000);
        }
    }

    render() {
        return (
        <Layout data={this.props.data} location={this.props.location}>
            <Helmet>
            <title>誰でも簡単に遊べるマルチタスクゲーム　「Cookin&#x27; Burger」！</title>
            <meta name="description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin&#x27; Burger」!" />
            <meta name="keywords” content=”DEA,DEAP,DEP,blockchain,ブロックチェーン,PlayMining" />
            <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
            <meta property="og:title" content="Cookin' Burgerで遊んでいるよ！" />
            <meta property="og:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin&#x27; Burger」!" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content="Cookin' Burgerで遊んでいるよ！" />
            <meta name="twitter:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin&#x27; Burger」!" />
            <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
            <script>
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1487009848391690');
            fbq('track', 'PageView');
            fbq('track', 'CompleteRegistration');`}
            </script>
            <noscript>{`
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1487009848391690&ev=PageView&noscript=1"/>`}
            </noscript>
            <meta name="facebook-domain-verification" content="f4x8mi0ic9i3xuu2qj1vij2jsld4pk" />
            </Helmet>
            <div className="l-main">
            <div className="l-term-section">
                <div className="section-inner_m">
                    <div className="l-term-section-box" style={{marginTop: "10vh"}}>
                    <h2 className="section-title" style={{fontSize: '1.6rem', textAlign:"center", lineHeight: 1.7}}>自動的に遷移します。遷移しない場合は<a style={{color: '#FFF!important'}} className="game-link" href="https://game.cookinburger.com/"><span className="linkWhite">[こちら]</span></a>をクリックしてください。</h2>
                    </div>
                </div>
            </div>
            </div>
        </Layout>
        )
    }
}

ThanksJaPage.propTypes = propTypes

export default ThanksJaPage

export const pageQuery = graphql`
  query ThanksJaQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
